<template>
    <div>
        <div class="w3-bar w3-top w3-theme-d2" style="z-index:4">
            <div class="w3-left w3-hide-small" style="margin-top:2px; padding-left:8px">
                <img src="assets/images/logo.png" class="w3-circle" style="width:35px">
                <span><b> SUKSES GROUP</b></span>
            </div>
            <button class="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-white" v-on:click="openMenu()"><i class="fa fa-bars"></i> &nbsp;Menu</button>
            <a href="../"><span class="w3-bar-item w3-right w3-theme-d2 w3-btn w3-round w3-border-left"><i class="fa fa-home"></i>&nbsp; Home</span></a>
        </div>
        <!-- Sidebar/menu -->
        <nav class="w3-sidebar w3-collapse w3-white w3-animate-left w3-theme w3-small" style="z-index:3;width:250px;overflow: visible;" id="mySidebar"><br>
            <div class="w3-container w3-row">
                <div class="w3-col s4">
                <img src="assets/images/admin.png" class="w3-circle w3-margin-right" style="width:50px">
                </div>
                <div class="hmntop w3-col s8 w3-bar">
                <span>Welcome, <br><strong>{{username}}</strong></span><br>
                <!-- <div class="w3-dropdown-hover w3-button w3-round-xxlarge"><i class="fa fa-gear"></i>
                    <div class="w3-dropdown-content w3-center w3-round-large" style="min-width: 0px !important;">
                    <a href="../login/profile.php" class="w3-bar-item w3-button w3-round-xxlarge" title="Profile"><i class="fa fa-gear"></i></a>
                    <button id="theme" class="w3-bar-item w3-button w3-round-xxlarge" title="Theme"><i class="fa fa-paint-brush"></i></button>
                    </div>
                </div> -->
                <a class="w3-bar-item w3-button w3-round-xxlarge" title="Profile" v-on:click="loadProfile()"><i class="fa fa-gear"></i></a>
                <a class="w3-bar-item w3-button w3-round-xxlarge" title="Log out" v-on:click="logoutSystem()"><i class="fa fa-sign-out"></i></a>
                </div>
            </div>
            <div class="w3-bar-block w3-round-xlarge w3-theme-l2 scrl-y" style="direction: rtl;padding-top: 13px;padding-bottom: 13px;" v-bind:style="'max-height:'+  (sideHeight-193) +'px;'">
                <div class="hmenu" style="direction:ltr;">
                    <a href="#/" class="w3-bar-item w3-button mn w3-padding tema" :class="menuinduk=='/home'?'active w3-theme-l4':''"><i class="fa fa-dashboard fa-fw"></i>&nbsp; Dashboard</a>
                    <a href="#/d-customer" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/d-customer'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Customer</a>
                    <a href="#/d-supplier" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/d-supplier'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Supplier</a>
                    <!-- <a href="#/checkin" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/checkin'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Checkin</a> -->
                    <div class="w3-container w3-padding tema">
                        <i class="fa fa-list fa-fw"></i>&nbsp; Masters
                    </div>
                    <!-- <div v-show="level == '01' || level == '11'"> -->
                        <a href="#/customer" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/customer'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Customer</a>
                        <a href="#/gudang" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/gudang'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Gudang</a>
                        <a href="#/item" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/item'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Item</a>
                        <a href="#/kendaraan" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/kendaraan'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Kendaraan</a>
                        <!-- <a href="#/location" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/location'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Location</a> -->
                    <!-- </div> -->
                    <div class="w3-container w3-padding tema">
                        <i class="fa fa-line-chart fa-fw"></i>&nbsp; Sales
                    </div>
                        <a href="#/target" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/target'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Target</a>
                        <a href="#/kunjungan" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/kunjungan'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Kunjungan</a>
                    <!-- <a href="#/simcard" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/simcard'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Simcard</a> -->
                    <div class="w3-container w3-padding tema">
                        <i class="fa fa-newspaper-o fa-fw"></i>&nbsp; Report
                    </div>
                        <a href="#/itemstock" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/itemstock'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Item Stock</a>
                    <div v-show="level == '01' || level == '11'">
                        <div class="w3-container w3-padding tema">
                            <i class="fa fa-codepen fa-fw"></i>&nbsp; Packing
                        </div>
                        <a href="#/stapel" class="w3-bar-item w3-button mn w3-padding menu-anak" :class="menuinduk=='/stapel'?'active w3-theme-l4':''"><i class="fa fa-book fa-fw"></i>&nbsp; Stapel</a>
                    </div>
                </div>
            </div>
            <div class="w3-bar w3-center w3-theme-l1" style="bottom: 0px;height: 38px;position: fixed;width: 250px;">
                <div class="w3-container" style="margin-top: 10px;">
                    <span>
                        <b>w3.css</b> by <a href="https://wa.me/6285749089991" target="_blank" class="w3-text-blue">@t-tabutyy</a> 
                    </span>
                </div>
            </div>
        </nav>
        <div class="w3-main w3-theme-l4" style="margin-left:250px">
			<keep-alive>
				<router-view class="w3-padding" style="position:relative;overflow: auto;"></router-view>
			</keep-alive>
		</div>
        <div class="w3-overlay w3-hide-large w3-animate-opacity" v-on:click="closeMenu()" style="cursor:pointer" title="close side menu" id="myOverlay"></div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "Dashboard",
    components: {},
    data() {
        return {
            sideHeight : 0,
            username: "",
            panel: false,
            appshow: false,
            menus: [],
            // pr_code: localStorage.program,
            menuinduk: localStorage.menuinduk,
            level: localStorage.tsaleslevel,
            txtcari: "",
            selectedRow: '',
            namaapp : this.appName
        };
    },
    methods: {
        loadMenu() {
            let self = this;
            // let obj = await self.deAsync("/tproject/admin/getmenu", {
            //     pr_code: self.pr_code,
            // });
            self.username = localStorage.username;
            // self.menus = obj.data.rec;
        },
        openMenu() {
            var mySidebar = document.getElementById("mySidebar");
            var overlayBg = document.getElementById("myOverlay");

            if (
                mySidebar.style.display === "block!important" ||
                mySidebar.style.display === "block"
            ) {
                mySidebar.style.display = "none";
                overlayBg.style.display = "none";
            } else {
                mySidebar.style.display = "block";
                overlayBg.style.display = "block";
            }
        },
        closeMenu() {
            let mySidebar = document.getElementById("mySidebar");
            let overlayBg = document.getElementById("myOverlay");
            mySidebar.style.display = "none";
            overlayBg.style.display = "none";
        },
        getIcon(mn) {
            if(mn.mn_type=='P'){
                if (mn.mn_icon != "" && mn.mn_icon != null && mn.mn_icon != "-") {
                    return mn.mn_icon;
                } else {
                    return "fa fa-book";
                }
            }else{
                return '';
            }
        },
        logoutSystem() {
            var self = this;
            swal(
                {
                    title: "Keluar Program",
                    text: "Yakin Ingin Keluar Program ..!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Keluar",
                    cancelButtonText: "Tidak",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                },
                function (isConfirm) {
                    if (isConfirm) {
                        localStorage.clear();
                        self.$router.push("/../login");
                        location.reload();
                    }
                }
            );
        },
        loadProfile(){
            this.$router.push("/profile");
        }
    },
    watch: {
        $route: function () {
            this.menuinduk = this.$route.path;
            this.closeMenu();
        },
    },
    mounted() {
        this.sideHeight = window.innerHeight;
        this.menuinduk = this.$route.path;
        // this.namaapp = this.appName;
        this.loadMenu();
    },
};
</script>
